<template>
  <div>
    <div class="invest-holder">
      <!-- <RouterLink to="/dashboard/assetId" > -->
      <div
        class="invest-card"
        v-for="(item, index) in activeInvesment"
        :key="index"
      >
        <div class="invest-card__header">
          <div class="invest-card__header--name">
            <div>
              <img
                src="../../assets/images/invest_card_icon.svg"
                alt="card icon"
              />
            </div>
            <div>
              <p class="invest-card__title">{{ item.investmentname }}</p>
              <p class="invest-card__price">
                ₦{{ Number(item.costperslot).toLocaleString() }}
                <span>/slot</span>
              </p>
              <p v-if="item.type === 'Collaterized_Investment'" class="invest-card__type">Collaterized Investment</p>
              <p v-else class="invest-card__type">{{ item.type }}</p>
            </div>
          </div>
          <div class="invest-card__badge" :class="[item.status != 'Ongoing' ? 'soldout' : '']">
            {{ item.status }}
          </div>
        </div>

        <div class="invest-card__info">
          <div>
            <span>Hol. Period</span>
            <p>{{ item.holding_period }}</p>
          </div>
          <div>
            <span>Slots</span>
            <p>{{ item.computedremaining }}/{{ item.numberofslots }}</p>
          </div>
          <div>
            <span>Est. ROI</span>
            <p>{{ item.roi }}%</p>
          </div>
          <div>
            <span>Starts</span>
            <p>{{ item.startdatedisplay }}</p>
          </div>
        </div>
        <div class="view-details">
          <p @click="investdetails(item.id)">
            View details
          </p>
        </div>
      </div>
      <!-- </RouterLink> -->
    </div>
    <div class="empty__state" v-if="cooporativeData.running_investments">
      <div class="empty__div" v-if="cooporativeData.running_investments.length == 0">
        <img src="../../assets/images/empty-state.svg" alt="" />
        <p>No opportunities to invest into at the moment!</p>
        <!-- <p>
          Enter the property pin of your desired property to view rent details
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Service } from "../../store/service";
const Api = new Service();
export default {
  props: ["cooporativeData", "activeInvesment"],
  computed: {
    welcome() {
      return `${this.cooporativeData}`;
    }
  },
  mounted() {
    this.coporativeData = this.cooporativeData;
  },
  methods: {
    investdetails(id) {
      return this.$router.push({
        path: `/dashboard/assetId/${id}`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$gray: #d7d7d7;
$yellow: #ffb100;

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.invest-card {
  background-color: #fff;
  padding: 1rem;
  text-align: left;
  border-radius: 10px;
  &__header {
    display: flex;
    justify-content: space-between;

    &--name {
      display: flex;
      gap: 12px;
    }
  }
  &__badge {
    font-size: 10px;
    color: #2bc300;
    background-color: #e7faf1;
    align-self: baseline;
    padding: 4px 8px;
    border-radius: 10px;
    white-space: nowrap;
  }
  &__title {
    font-size: 18px;
  }
  &__price {
    font-size: 18px;
    font-weight: 700;
    span {
      font-weight: 400;
      font-size: 14px;
    }
  }

  &__type {
    font-size: 10px;
    color: #57595a;
    background: #f9f9f9;
    padding: 2px 4px;
    border-radius: 8px;
    display: inline-block;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    p {
      font-weight: 600;
      font-size: 13px;
    }

    span {
      font-size: 10px;
    }

    > * {
      flex: 1;
      text-align: center;
    }
    & > * + * {
      border-left: 1px solid #ebebeb;
    }
  }
}
.soldout {
  color: #EB0000 !important;
  background: #f7e7e7 !important;
}
.invest-holder {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  gap: 1rem;
}
.view-details {
  margin-top: 19px;
  margin-left: 24px;
  p {
    color: $primary;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    cursor: pointer;
  }
}

.empty__div {
  padding: 1rem;

  img {
    width: 100%;
    max-width: 470.58px;
  }

  p:nth-child(2) {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    color: $black2;
    padding-top: 1rem;
    margin-bottom: 0;
  }

  p:nth-child(3) {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    color: $black2;
  }
}

@media screen and (max-width: 599px) {
  .empty__div {
    p:nth-child(3) {
      font-size: 13px;
    }
  }
}
</style>
