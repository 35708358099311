<template>
  <div class="container">
    <div v-if="hideDiv">
      <PageLoader></PageLoader>
    </div>
    <div v-else>
      <div class="d-flex align-items-baseline invest-text">
        <div class="invest-logo">
          <img src="../assets/icons/cooporative-icon.svg" alt="cooporative">
        </div>
        <span>Investments</span>
      </div>
      <!-- <BalanceHeader :cooporativeData="sentData" /> -->
      <div class="invest-main">
        <div>
          <nav class="invest-nav">
            <div class="invest-nav__main">
              <div
                class="invest-nav__main-inner"
                :class="{ 'tab-2-active': tab == 2, 'tab-3-active': tab == 3 }"
              >
                <button @click="tab = 1">Explore Assets</button>
                <button @click="tab = 2">My Investments</button>
                <!-- <button @click="tab = 3">Transaction History</button> -->
              </div>
            </div>
            <div class="invest-nav__main">
              <button @click="investType = 1; filterInvestment()" :class="[investType == 1 ? 'activeInvest' : '']">
                All
              </button>
              <button @click="investType = 2; filterInvestment()" :class="[investType == 2 ? 'activeInvest' : '']">Fixed Investments</button>
              <button @click="investType = 3; filterInvestment()" :class="[investType == 3 ? 'activeInvest' : '']">Targeted Investments</button>
              <button @click="investType = 4; filterInvestment()" :class="[investType == 4 ? 'activeInvest' : '']">Collaterized Investment</button>
            </div>
          </nav>
        </div>
        <!-- cards -->
        <div class="explore-assets"></div>

        <div class="" v-if="tab == 2">
          <div class="my-investments">
            <div v-if="userInvestments.length > 0">
              <div
                class="investments"
                v-for="(invest, index) in userInvestments"
                :key="index"
              >
                <div class="investment">
                  <div class="investment__detail1">
                    <div class="asset-img d-none d-md-block">
                      <img src="../assets/images/invest_card_icon-large.svg" />
                    </div>
                    <div>
                      <p>{{ invest.cooperative_investment.investmentname }}</p>
                      <p>
                        {{
                          invest.cooperative_investment.startdatedisplay
                            | moment
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="investment__detail2">
                    <p>₦{{ Number(invest.total).toLocaleString() }}</p>
                    <p>{{ invest.quantity }} slots</p>
                  </div>
                </div>
                <!-- <div class="investment">
                  <div class="investment__detail1">
                    <div class="asset-img">
                      <img src="../assets/images/invest_card_icon-large.svg" />
                    </div>
                    <div>
                      <p>Rent Gap Financing</p>
                      <p>Jan 3rd,2023</p>
                    </div>
                  </div>
                  <div class="investment__detail2">
                    <p>₦15,000.00</p>
                    <p>3 slots</p>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="empty" v-else>
              <h3>No investments</h3>
              <p>Get started by creating a new investment</p>
              <button class="main mt-4" @click="tab = 1">Invest now</button>
            </div>
          </div>
        </div>
        <div class="assets" v-if="tab == 1">
          <InvestmentCard :cooporativeData="sentData" :activeInvesment="runningActiveInvestments"></InvestmentCard>
          <!-- <InvestmentCard :cooporativeData="sentData"/>
        <InvestmentCard :cooporativeData="sentData"/> -->
        </div>

        <!-- <div class="transaction-history table-responsive" v-if="tab == 3">
          <table class="table ">
            <thead>
              <tr>
                <th scope="col">Description</th>
                <th scope="col">Date</th>
                <th scope="col">Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody
              v-for="(data, index) in userInvestmentsTransactions"
              :key="index"
            >
              <tr>
                <th scope="row">
                  <p>{{ data.description }}</p>
                </th>
                <td style="white-space: nowrap;">
                  <p>{{ data.created_at | moment }}</p>
                  <p>{{ data.created_at | timemoment }}</p>
                </td>
                <td>₦{{ Number(data.amount).toLocaleString() }}</td>
                <td>
                  <a
                    href="#"
                    v-b-toggle.sidebar-right
                    @click="expandedTransactionDetails = data"
                    >View</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination class="mt-5"
            v-if="userInvestmentsTransactions"
            @pageData="dataFromChild($event)"
          ></Pagination>
        </div> -->
        <b-sidebar id="sidebar-right" title="Transaction details" right shadow>
          <div class="px-3 py-2">
            <div class="top-holder">
              <img
                src="../assets/images/invest_card_icon-large.svg"
                alt="invest"
              />
              <div>
                <p>{{ expandedTransactionDetails.description }}</p>
                <small
                  >REF:
                  {{ expandedTransactionDetails.transaction_reference }}</small
                >
              </div>
            </div>
            <div class="detail-body">
              <div class="details-slot">
                <h5>Amount</h5>
                <h6>
                  ₦{{
                    Number(expandedTransactionDetails.amount).toLocaleString()
                  }}
                </h6>
              </div>
              <div class="details-slot" v-if="expandedTransactionDetails.cost_per_slot != null">
                <h5>Amount per slot</h5>
                <h6>{{ expandedTransactionDetails.cost_per_slot }}</h6>
              </div>
              <div class="details-slot" v-if="expandedTransactionDetails.number_of_slots != null">
                <h5>Number of slot</h5>
                <h6>{{ expandedTransactionDetails.number_of_slots }}</h6>
              </div>
              <div class="details-slot">
                <h5>Channel</h5>
                <h6>{{ expandedTransactionDetails.channel }}</h6>
              </div>
              <div class="details-slot">
                <h5>Type</h5>
                <h6>{{ expandedTransactionDetails.type }}</h6>
              </div>
              <div class="details-slot">
                <h5>Transaction status</h5>
                <h6>{{ expandedTransactionDetails.status }}</h6>
              </div>
              <div class="details-slot">
                <h5>Payment method</h5>
                <h6>{{ expandedTransactionDetails.channel }}</h6>
              </div>
            </div>
          </div>
        </b-sidebar>
      </div>
    </div>
  </div>
</template>

<script>
// import BalanceHeader from "../components/invest/BalanceHeader";
import InvestmentCard from "../components/invest/InvestmentCard";
import { Service } from "../store/service";
const Api = new Service();
import PageLoader from "../components/PageLoader.vue";
// import Pagination from "../components/Pagination.vue";
import moment from "moment";
export default {
  components: {
    // BalanceHeader,
    InvestmentCard,
    PageLoader,
    // Pagination
  },
  data() {
    return {
      page: 1,
      limit: 10,
      // totalcount: "",
      tab: 1,
      // stillActive: false,
      investment: "",
      userInvestments: [],
      sentData: {},
      runningActiveInvestments: [],
      investType: 1,
      activeTab: 1,
      hideDiv: false,
      userInvestmentsTransactions: "",
      expandedTransactionDetails: {},
      // investmentUrl: ""
    };
  },
  created() {
    this.$root.$refs.fundInvest = this;
  },
  mounted() {
    this.investmentData();
  },
  methods: {
    dataFromChild(data) {
      // console.log("data from child", data.data.wallettrans);
      this.userInvestmentsTransactions = data.data.wallettrans;
    },
    // pageUrlGlobal() {
    //   Api.pushPageURL(`getcooperativetransactions`);
    // },
    filterInvestment() {
      if (this.investType == 1 && this.activeTab != 1) {
        // this.investmentUrl = "getusercooperativedata";
        this.investmentData();
        this.activeTab = 1;
      }
      if (this.investType == 2 && this.activeTab != 2) {
        // this.investmentUrl = "fetchtfixedinvestements";
        this.investmentDataFixed();
        this.activeTab = 2;
      }
      if (this.investType == 3 && this.activeTab != 3) {
        // this.investmentUrl = "fetchtargetedinvestments";
        this.investmentDataTargeted();
        this.activeTab = 3;
      }
      if (this.investType == 4 && this.activeTab != 4) {
        // this.investmentUrl = "fetchtargetedinvestments";
        this.investmentDataCollaterized();
        this.activeTab = 4;
      }
    },
    investmentDataFixed() {
      this.hideDiv = true;
      Api.getRequest(`fetchtfixedinvestements`)
        .then(res => {
          this.hideDiv = false;
          if (res.data.success) {
            // console.log("Invest data fixed", res);
            this.runningActiveInvestments = res.data.investments;
            // this.userInvestments = res.data.investments;
            this.investmentMoreData();
          }
          if (res.data.error) {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          this.hideDiv = false;
          console.log(err);
          this.$store.commit("setApiFailed", "Error occured");
        });
    },
    investmentDataTargeted() {
      this.hideDiv = true;
      Api.getRequest(`fetchtargetedinvestments`)
        .then(res => {
          this.hideDiv = false;
          if (res.data.success) {
            // console.log("Invest data", res);
            // this.sentData = res.data.investments;
            this.runningActiveInvestments = res.data.investments;
            // this.userInvestments = res.data.investments;
            this.investmentMoreData();
          }
          if (res.data.error) {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          this.hideDiv = false;
          console.log(err);
          this.$store.commit("setApiFailed", "Error occured");
        });
    },
    investmentDataCollaterized() {
      this.hideDiv = true;
      Api.getRequest(`fetchcollatarellizedinvestments`)
        .then(res => {
          this.hideDiv = false;
          if (res.data.success) {
            // console.log("Invest data", res);
            // this.sentData = res.data.investments;
            this.runningActiveInvestments = res.data.investments;
            // this.userInvestments = res.data.investments;
            this.investmentMoreData();
          }
          if (res.data.error) {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          this.hideDiv = false;
          console.log(err);
          this.$store.commit("setApiFailed", "Error occured");
        });
    },
    investmentData() {
      this.hideDiv = true;
      Api.getRequest(`getusercooperativedata`)
        .then(res => {
          this.hideDiv = false;
          if (res.data.success) {
            // console.log("Invest data", res);
            this.sentData = res.data;
            this.runningActiveInvestments = res.data.running_investments;
            this.userInvestments = res.data.user_investments;
            this.investmentMoreData();
          }
          if (res.data.error) {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          this.hideDiv = false;
          console.log(err);
          this.$store.commit("setApiFailed", "Error occured");
        });
    },
    async investmentMoreData() {
      // this.hideDiv = true;
      try {
        await Api.getRequest(`getcooperativetransactions?page=${this.page}&perpage=${this.limit}`)
          .then(res => {
            this.hideDiv = false;
            if (res.data.success) {
              // this.totalcount = res.data.totalpages;
              // console.log("Invest more data", res);
              // this.sentData = res.data;
              // this.totalcount = res.data.totalpages;
              // this.pageUrlGlobal();
              // this.userInvestmentsTransactions
              this.userInvestmentsTransactions = res.data.wallettrans;
            }
            if (res.data.error) {
              this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.hideDiv = false;
            console.log(err);
            this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("Finally");
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMM Do YYYY");
    },
    timemoment: function(date) {
      return moment(date).format("hh:mm:ss a");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$black: #000000;

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

img {
  width: 100%;
}

button.main {
  background-color: $primary;
  font-family: Lato;
  color: #fff;
  padding: 14px 2rem;
  border-radius: 4px;
  transition: all 350ms ease-in-out;

  &:hover {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  }
}

.invest-text {
  span {
    font-family: Lato;
    font-size: 22px;
    color: #000a2f;
  }
}
.invest-logo {
  background: $primary;
  width: max-content;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.invest-nav__main-inner {
  button {
    color: #57595a;
    text-align: center;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    font-family: Lato;
  }
}

.invest-nav__main {
  button {
    color: #57595a;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }
}

.activeInvest {
  color: $primary !important;
  font-family: Lato;
}

.assets {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.invest-main {
  display: grid;
  gap: 1rem;
  padding: 0;
  // padding: 2rem 0;
}

button {
  border: none;
  background-color: transparent;
  font-family: Lato;
}

.invest-nav {
  color: #57595a;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  margin-top: 1rem;
  text-align: left;
  font-family: Lato;

  &__main {
    font-size: 1rem;
    border-bottom: 2px solid #e2e8f0;
    font-family: Lato;
    display: flex;

    &-inner {
      max-width: 500px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        width: calc(95% / 2);
        left: 0;
        bottom: 0;
        background-color: $primary;
        height: 2px;
        transition: transform 150ms ease-in;
      }
    }

    > * {
      padding: 1rem;
    }
  }

  &__secondary {
    padding: 1rem;
  }
}

.my-investments {
  background: #fff;
  border-radius: 10px;
  padding: 20px 24px 20px;

  * {
    margin: 0;
    padding: 0;
  }
}

.container {
  padding-top: 2rem;
}

.investments {
  border-bottom: 1px solid #e2e8f0;
}

.investments:last-child {
  border-bottom: none;
}

.investment {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 16px;
  margin-bottom: 16px;
  padding-top: 16px;

  &__detail1 {
    text-align: left;
    display: flex;
    align-items: baseline;
    gap: 1rem;
    font-family: Lato;

    .asset-img {
      width: 40px;
    }

    p:first-child {
      font-weight: bold;
      font-size: 24px;
      font-family: Lato;
    }
  }

  &__detail2 {
    text-align: right;
    font-family: Lato;
  }
}

.investment:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.transaction-history {
  background: #fff;
  border-radius: 10px;

  table p {
    margin: 0;
    font-weight: normal;
    // font-size: clamp(10px, 3vw, 16px);
    // font-size: 18px;
    font-family: Lato;
  }
}

.tab-2-active::after {
  /* left: calc(100% / 2); */
  transform: translateX(100%);
}

.tab-3-active::after {
  /* left: calc(100% / 2); */
  transform: translateX(200%);
}

// Side drawer details starts
.top-holder {
  display: flex;
  align-items: center;
  margin-top: 20px;

  img {
    width: 55px;
  }

  div {
    margin-left: 12px;
    text-align: left;

    p {
      color: #000a2f;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      margin-bottom: 0;
    }

    small {
      color: #555c74;
      font-family: Lato;
      // font-size: 14px;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
}

.details-slot {
  display: flex;
  align-content: center;
  justify-content: space-between;
  gap: 5px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e7e7e7;
  text-transform: capitalize;

  h5 {
    color: #555c74;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 16px;
    text-align: left;
  }

  h6 {
    color: #000a2f;
    text-align: right;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 16px;
  }
}

// .b-sidebar-header {
//   flex-direction: row-reverse !important;
//   background: red !important;
// }

// .b-sidebar.b-sidebar-right>.b-sidebar-header .close {
//   margin-right: none !important;
// }

::v-deep .b-sidebar-header {
  flex-direction: row-reverse !important;
  justify-content: space-between;
}
::v-deep .close {
  margin-right: 0 !important;
}

// b-sidebar {
//   .b-sidebar-header {
//   flex-direction: row-reverse !important;
// }
// }

// Side drawer details ends


.empty {
  h3 {
    font-family: Lato;
  }
  p {
    font-family: Lato;
  }
}
@media screen and (max-width: 599px) {
  .invest-nav__main {
    button {
      font-size: 12px;
    }
  }

  .transaction-history {
    table p {
      margin: 0;
      // font-size: clamp(10px, 3vw, 16px);
      font-size: 15px;
    }
  }

  .investment__detail1 {
    p:first-child {
      font-size: 15px;
    }
  }

  .investment__detail12 {
    p {
      font-size: 15px;
    }
  }
}
</style>
